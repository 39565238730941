import {API} from 'aws-amplify'


function makePath(path, fmt = "") {
    return path.filter(v => v !== undefined).map(v => encodeURIComponent(v)).join('/') + fmt
}

async function authenticatedUrl(path, fmt = "") {
    if (process.env.VUE_APP_LOCAL === undefined) {
        console.log("Making Authenticated URL for", path)
        return (await API.endpoint("api")) + '/' + makePath(path, fmt) + "?access_token=" + encodeURIComponent((await API.Auth.currentSession()).getIdToken().getJwtToken())
    } else {
        console.log("Making Unauthenticated URL for", path)
        return (await API.endpoint("api")) + '/' + makePath(path, fmt)
    }
}

function apiGet(path, fmt = "", queryParameters = {}, responseType = "json") {
    let myInit = {
        queryStringParameters: queryParameters,
        responseType: responseType
    }
    return API.get('api', '/' + makePath(path, fmt), myInit);
}

async function apiPost(path, body) {
    let myInit = {
        body: body,
        headers: {'Content-Type': 'application/json'}
    }
    return API.post('api', '/' + makePath(path), myInit);
}


async function apiPut(path, body) {
    // console.log('PUT', path, body)
    let myInit = {
        body: body,
        headers: {'Content-Type': 'application/json'}
    }
    return API.put('api', '/' + makePath(path), myInit);
}


async function apiDelete(path) {
    let myInit = {

    }
    return API.del('api', '/' + makePath(path), myInit);
}

/**
 * Loads companies available to the current user
 * @return {Promise<Array<Object>>}
 */
function loadCompanies() {
  return apiGet(['companies'])
    .then(response => {
      return response["companies"]
    })
}


/**
 * Loads orchards available to the current user
 * @return {Promise<Array<Object>>}
 */
function loadOrchards() {
  return apiGet(['orchards'])
    .then(response => {
      return response["orchards"]
    })
}

/**
 * Loads scan(s) data
 * @param {string} company - company code from orchard
 * @param {string} kpin - orchard KPIN
 * @param {string} scan_id - scan ID or scan year
 *
 * @return {Promise<Object>}
 */
function loadScans(company, kpin, scan_id = undefined) {
    return apiGet(['scans', company, kpin, scan_id])
        .then(response => {
            return {'company': company, 'kpin': kpin, 'scan_id': scan_id, 'scans': response["scans"]}
        })
}

/**
 * Loads bay scan details (including individual detections)
 *
 * @param {string} company - company code from orchard
 * @param {string} kpin - orchard KPIN
 * @param {string} scan_id - scan ID
 * @param {string} block - block name
 * @param {string} row - row number
 * @param {string} bay - bay number
 */
async function loadBayScanDetails(company, kpin, scan_id, block, row, bay) {
  return apiGet(['scans', company, kpin, scan_id, block, row, bay], ".json")
}

/**
 * Loads Orchard Map
 * @param {string} company - company code from orchard
 * @param {string} kpin - orchard KPIN
 */
async function loadOrchardMap(company, kpin) {
    return apiGet(['maps', company, kpin], "", {}, "document")
}

/**
 * Loads user settings
 *
 * @return {Promise<Object>}
 */
function loadSettings() {
    return apiGet(['settings'])
}

/**
 * Store user settings.
 * Only updates settings present in request.
 * To remove setting - set to null
 * Key must not contain '/'
 *
 * @param {Object} settings - user settings.
 *
 * @return {Promise<Object>}
 */
function storeSettings(settings) {
    return apiPost(['settings'], settings)
}


/**
 * Generates a URL to download scan files in provided format
 * @param {string} company - company code from orchard
 * @param {string} kpin - orchard KPIN
 * @param {string} scan_id - scan ID or scan year
 * @param {string} fmt - required format. One of: "xlsx", "csv", "xml", "json"
 *
 */
async function scanDownloadUrl(company, kpin, scan_id= undefined, fmt = "xlsx") {
    return authenticatedUrl(['scans', 'download', company, kpin, scan_id], "." + fmt)
}

/**
 * Generates a URL to download bay image
 * @param {string} company - company code from orchard
 * @param {string} kpin - orchard KPIN
 * @param {string} scan_id - scan ID
 * @param {string} block - block name
 * @param {string} row - row number
 * @param {string} bay - bay number
 * @param {boolean} thumb - generate thumbnail image
 */
async function scanImageUrl(company, kpin, scan_id, block, row, bay, thumb=false) {
    return authenticatedUrl(['scans', 'download', company, kpin, scan_id, block, row, bay]).then((full_url) => {
        return authenticatedUrl(['scans', 'download', company, kpin, scan_id, block, row, bay], '.thumb').then((thumb_url) => {
          return {full: full_url, thumb: thumb_url}
        })
      }
    )
}


export {loadCompanies, loadOrchards, loadScans, loadSettings, storeSettings, loadBayScanDetails, loadOrchardMap,
    scanImageUrl, scanDownloadUrl};
