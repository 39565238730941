<template>
  <amplify-auth-container v-if="$root.with_login">
    <amplify-authenticator>
      <amplify-sign-in hide-sign-up="true" slot="sign-in" username-alias="email"/>
      <div>
        <notifications transition-name="notification-list" transition-mode="out-in"></notifications>
        <router-view name="header"></router-view>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
        <!--    <router-view name="footer"></router-view>-->
      </div>
    </amplify-authenticator>
  </amplify-auth-container>
  <div v-else>
    <notifications transition-name="notification-list" transition-mode="out-in"></notifications>
    <router-view name="header"></router-view>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <!--    <router-view name="footer"></router-view>-->
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import 'sweetalert2/dist/sweetalert2.css'
import 'vue-notifyjs/themes/default.css'

import { onAuthUIStateChange } from '@aws-amplify/ui-components';

export default {
  name: 'App',
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.$root.authState = authState;
      this.$root.user = authData;
    });
  },
  data() {
    return {
      unsubscribeAuth: undefined,
    };
  },
  beforeDestroy() {
    this.unsubscribeAuth();
  },
};
</script>

<style lang="scss">

</style>
