/*!

  =========================================================
  * Vue Paper Dashboard 2 PRO - V2.4.0
  =========================================================

  * Product Page: https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Available with purchase of license from https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Copyright 2019 Creative Tim (https://www.creative-tim.com)
  * License Creative Tim (https://www.creative-tim.com/license)

  =========================================================

*/

import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
import VueNotify from 'vue-notifyjs'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import App from './App.vue'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import initProgress from './progressbar';

// router setup
import routes from './routes/routes'

// library imports

import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'

import './registerServiceWorker'

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import { Amplify, Auth } from 'aws-amplify';
import {loadOrchards, scanDownloadUrl, loadCompanies, loadScans } from "@/util/api";

// plugin setup
Vue.use(VueRouter)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
locale.use(lang)

Amplify.configure({
    Auth: process.env.VUE_APP_LOCAL === undefined ? {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
        region: process.env.VUE_APP_COGNITO_REGION,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        //identityPoolRegion: 'ap-southeast-2',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.VUE_APP_COGNITO_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.VUE_APP_COGNITO_APP_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: '.yourdomain.com',
        //     // OPTIONAL - Cookie path
        //     path: '/',
        //     // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        //     // OPTIONAL - Cookie secure flag
        //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     secure: true
        // },

        // OPTIONAL - customized storage object
        // storage: new MyStorage(),

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        //authenticationFlowType: 'USER_SRP_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: {myCustomKey: 'myCustomValue'},

    }: {},
    API: {
        endpoints: [
            {
                name: "api",
                endpoint: process.env.VUE_APP_API_URL,
                custom_header: process.env.VUE_APP_LOCAL === undefined ? async () => {
                    return {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
                } : () => { return {} }
            },
        ]
    }
})

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})

initProgress(router);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  data: {
    orchard: {"id": null, "name": null, "scans": []},
    scan: null,
    orchards: [],
    companies: {},
    scans: [],
    settings: {},
    full_refresh: false,
    user: undefined,
    authState: undefined,
    with_login: process.env.VUE_APP_LOCAL === undefined
  },
  computed: {

  },
  watch: {
    full_refresh:  function (val) {
      if (val) {
        if (!this.$route.query['autoreload']) {
          this.loadOrchardScans(this.orchard)
        }
      }
    },
    authState: async function (newAuthState) {
      console.debug("main.js: newAuthState:", newAuthState)
      let self = this
      if (newAuthState === 'signedin') {
        // TODO load company info
        loadOrchards().then(orchards => {
          console.debug("main.js: Load Orchards: orchards: ", orchards)
          orchards.sort((a, b) => a.kpin.localeCompare(b.kpin))
          self.orchards = orchards
          self.orchard = self.findOrchard(self.$route.params)
          self.loadOrchardScans(self.orchard)
        })
        loadCompanies().then(companies => {
          console.debug("main.js: Load Companies: companies: ", companies)
          self.companies = companies
        })
      }
    },
    orchard(orchard) {
      this.loadOrchardScans(orchard)
    },
    scan(s) {
      if (s) {
        console.debug("changeScan:", s.id)
        if (this.$route.params.map_orchard !== this.orchard.id || this.$route.params.map_scan !== s.id) {
          this.$router.push({
            'name': 'MapOrchardScan',
            params: {map_orchard: this.orchard.id, map_scan: s.id},
            query: this.$route.query
          })
        }
      } else {
        console.debug("changeScan: no scan")
        if (this.$route.params.map_orchard !== this.orchard.id) {
          this.$router.push({'name': 'MapOrchard', params: {map_orchard: this.orchard.id}, query: this.$route.query})
        }
      }
      this.full_refresh = true
    }
  },
  methods: {
    downloadScan(format) {
      if (format === "pdf") {
        this.$emit('pdf-download', {})
      } else {
        scanDownloadUrl(this.orchard.company, this.orchard.kpin, this.scan.id, format).then(result => {
          console.debug("scanDownloadUrl: result:", result)
          let link = document.createElement('a')
          link.href = result
          link.click();
        })
      }
    },
    loadOrchardScans(orchard, scan_id = undefined) {
      let self = this
      if (orchard) {
        window.document.title = orchard.name.replace(/^\w/, c => c.toUpperCase()) + ' Scan';
        loadScans(orchard.company, orchard.kpin, scan_id).then(raw_scans => {
          if (scan_id !== undefined) {
              for (let scan of self.scans) {
                if (!raw_scans.scans[scan.id]) {
                  raw_scans.scans[scan.id] = scan
                }
              }
              // TODO do full reload when another scan is selected?
          }
          self.scans = this.formatScans(raw_scans.scans)
          self.scan = this.findScan(this.$route.params)
          console.debug("self.scan:", self.scan)
          let autoreload = this.$route.query['autoreload']
          if (autoreload) {
            // TODO cancel timeout on change of orchard
            console.debug("Autoreload in ", autoreload, "seconds")
            setTimeout(() => {
              if (self.scan && !self.full_refresh) {
                console.debug("Autoreload", self.scan.id)
                self.loadOrchardScans(orchard, self.scan.id)
              } else if (self.full_refresh) {
                console.debug("Autoreload all")
                self.loadOrchardScans(orchard)
              }
              }, Number(autoreload) * 1000)
          } else {
            console.debug("Query", self.$route, window.location)
          }

        });
      }
    },
    parseTags(name) {
      let wparts = name.split(' ')
      let tags = Array()
      let words = Array()
      for (let wpart of wparts) {
        let hparts = wpart.split('#')
        let subTags = Array()
        for (let p of hparts) {
            subTags.push(p.trim().toLowerCase())
        }
        if (wpart.startsWith("#")) {
          for (let tag of subTags) {
            if (tag) {
              tags.push(tag)
            }
          }
        } else {
          words.push(hparts[0].trim())
          for (let tag of subTags.slice(1)) {
            if (tag) {
              tags.push(tag)
            }
          }
        }
      }
      return {text: words.join(" "), tags: tags}
    },
    formatScans(scans) {
      let scan_list = []
      for (let k in scans) {
        let scan = scans[k]
        scan.id = k
        let tags = this.parseTags(scan.name)
        scan.tags = tags.tags //.map((t) => { return {type: "error", label: t} })
        scan.simpleName = tags.text
        scan_list.push(scan)
      }
      scan_list.sort((a, b) => {
        if (a.date_to === b.date_to) {
          let a_updated_at = a.updated_at || a.date_to
          let b_updated_at = b.updated_at || b.date_to
          return new Date(b_updated_at) - new Date(a_updated_at)
        } else {
          return new Date(b.date_to) - new Date(a.date_to)
        }
      });
      return scan_list
    },
    findOrchard(route_params) {
      let return_orchard = null
      if (route_params.map_orchard) { // if an orchard was specified
        this.orchards.forEach(orchard => orchard.id === route_params.map_orchard ? return_orchard = orchard : null)
      }
      return return_orchard
    },
    findScan(route_params) {
      // let selected_scan = null
      let selected_scan = null
      if (route_params.map_scan) { // and if a scan was specified in route params...
        for (let scan of this.scans) {
          if (scan.id === route_params.map_scan) {
            selected_scan = scan
            break
          }
        }
      }
      if (!selected_scan) {
        if (this.scans) {
          selected_scan = this.scans[0]
        } else {
          selected_scan = null
        }
      }
      return selected_scan
    }
  },
  mounted() {
    if (!this.with_login) {
      setTimeout(() => {
        this.authState = "signedin"
      }, 500)
    }
  },
  render: h => h(App),
  router
})
